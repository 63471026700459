/* Provide sufficient contrast against white background */
a {
  color: #0366d6;
}

code {
  color: #E01A76;
}

.btn-primary {
  color: #fff;
  background-color: #1b6ec2;
  border-color: #1861ac;
}

.tableFix thead th {
    position: sticky;
    top: 0;
    text-align: center;
    vertical-align: middle;
}


table {
    border-collapse: collapse;
    width: 100%;
}

bottom{
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
}

th, td {
    padding: 8px 16px;
    border-top-width: 0px;
    text-align: center;
    vertical-align: middle;
}

th {
    background: #eee;
}

select {
    display: block;
    width: 100%;
    height: calc(1.5em + .75rem + 2px);
    padding: .375rem .75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: .25rem;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}

.Pendbtn {
    display: inline-block;
    font-weight: 400;
    color: #212529;
    text-align: center;
    vertical-align: middle;
    -webkit-user-select: none;
    user-select: none;
    background-color: transparent;
    border: 1px solid transparent;
    padding: .375rem .75rem;
    font-size: 1rem;
    line-height: 1.5;
    border-radius: .25rem;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    background-color: #17a2b882;
    border-color: black;
    color: #fff;
}
.react-datepicker {
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 0.8rem;
    background-color: #fff;
    color: #000;
    border: 1px solid #aeaeae;
    border-radius: 0.3rem;
    display: inline-block;
    position: relative;
    width: 313px;
}
    .react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__input input {
    width: 100px;
}


/* poupu */
.navBG {
    background-color: #6dd3d6 !important;
}
FooterPad{
    padding-top:60px;
}


@media (min-width: 768px) {
    .abs-center-x {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
    }
}

@media (max-width: 768px) {
    .abs-center-x {
        margin-left: auto !important
    }
}

.datepicker {
    padding-bottom: 6px;
    padding-top: 7px;
    padding-left: 12px;
}

button {
    border-radius: 10px;
    background-color: grey;
    outline: none;
}

    button:hover {
        background-color: white;
    }

.modal-custom {
    position: fixed;
    z-index: 1;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.25);
}

.modal-custom_content {
    background-color: white;
    position: absolute;
    top: 5%;
    left: 10%;
    width: 80%;
    padding: 20px;
    border-radius: 5px;
}

.close {
    color: Black;
    float: right;
}

    .close:hover {
        color: cyan;
        cursor: pointer;
    }

/* leaflet */
#mapid {
    height: 800px;
}

/* Position and sizing of burger button */
.bm-burger-button {
    position: fixed;
    width: 36px;
    height: 30px;
    left: 36px;
    top: 36px;
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
    background: #373a47;
}

/* Color/shape of burger icon bars on hover*/
.bm-burger-bars-hover {
    background: #a90000;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
    height: 24px;
    width: 24px;
}

/* Color/shape of close button cross */
.bm-cross {
    background: #bdc3c7;
}

/*
Sidebar wrapper styles
Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
*/
.bm-menu-wrap {
    position: fixed;
    height: 100%;
}

/* General sidebar styles */
.bm-menu {
    background: #373a47;
    padding: 2.5em 1.5em 0;
    font-size: 1.15em;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
    fill: #373a47;
}

/* Wrapper for item list */
.bm-item-list {
    color: #b8b7ad;
    padding: 0.8em;
}

/* Individual item */
.bm-item {
    display: inline-block;
}

/* Styling of overlay */
.bm-overlay {
    background: rgba(0, 0, 0, 0.3);
}